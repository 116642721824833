import menu from "./modules/menu";

window.addEventListener('DOMContentLoaded', () => {

    try {
        menu();
    } catch (error) { }

});






